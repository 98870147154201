import {useQuery} from "react-query";
import {IdToken, useAuth0} from "@auth0/auth0-react";

enum AccountLinkingPostResponseResult {
  SUCCESS = "success",
  ERROR = "error"
}

type AccountLinkingPostResponse = {
  primaryUserId: string;
  linkedAccountIds?: [];
  result: AccountLinkingPostResponseResult;
};

const auth0AccountMerge = async (getIdToken: () => Promise<IdToken>): Promise<AccountLinkingPostResponse> => {
  const response = await fetch(`${window.ENV.REACT_APP_CESARINE_COM_URL}/api/authorization/account-linking`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ idToken: (await getIdToken()).__raw }),
  });
  if (response.ok) {
    const accountLinkingResponse = (await response.json()) as AccountLinkingPostResponse;
    if (accountLinkingResponse.result === AccountLinkingPostResponseResult.SUCCESS) {
      console.log(`Account linking successful for primary user: ${accountLinkingResponse.primaryUserId} and linked accounts: ${accountLinkingResponse.linkedAccountIds}`);
    } else {
      console.warn(`Account linking failed for primary user: ${accountLinkingResponse.primaryUserId}`);
    }
    return accountLinkingResponse;
  } else {
    return;
  }
};

export const useAuth0AccountLinking = () => {
  // return a query that will trigger the account linking process (not mutation)
  const { getIdTokenClaims } = useAuth0();
  return useQuery("auth0-account-linking", async () => await auth0AccountMerge(getIdTokenClaims), {
    enabled: true,
  });
};
